import { Types } from '../config/types';
import { useContext, useRef } from 'react';
import { dataContext } from '../App';
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import useToken from '../hooks/useToken';
import useHandler from '../hooks/useHandler';

// #region Styled

const Main = styled.main`
	padding: var(--spacing-unit-default) var(--spacing-unit-s1);
	flex: 1;
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-default);
`;

const Head = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s2);
	
	div:first-child {
		font-size: var(--font-size-l1);
		font-weight: bold;
		line-height: var(--line-height-l1);
		color: hsl(var(--black-color));
	}
	
	div:last-child {
		font-size: var(--font-size-default);
		line-height: var(--line-height-default);
		color: hsl(var(--dark-gray-color));
	}
`;

const Form = styled.form`
	padding: 0 var(--spacing-unit-default);
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s1);
`;

const Input = styled.div`
	position: relative;
	
	input {
		padding-left: 52px;
		width: 100%;
	}
	
	input[id=password] {
		padding-right: 52px;
	}
`;

const Label = styled.label<{ $icon?: string; }>`
	position: absolute;
	
	${ props => {
		switch(props.$icon) {
			case 'mail':
				return 'padding: 10px 15.5px 11px 15.5px;';
			default:
				return 'padding: 9px var(--spacing-unit-s1) 11px var(--spacing-unit-s1);';
		}
	}}
	
	&::before {
		content: var(--${ props => props.$icon });
		color: hsl(var(--dark-gray-color));
		
		${ props => {
			switch(props.$icon) {
				case 'mail':
					return 'font-size: 21px;';
				default:
					return 'font-size: 22px;';
			}
		}}
	}
`;

const Eye = styled.button`
	margin: 3.5px 7.5px;
	position: absolute;
	top: 0;
	right: 0;
	
	&::before {
		font-size: 22px;
		color: hsl(var(--dark-gray-color));
	}
	
	&.eye::before {
		content: var(--eye);
	}
	
	&.eye-closed::before {
		content: var(--eye-closed);
	}
`;

const Option = styled.div`
	display: flex;
	column-gap: var(--spacing-unit-s1);
	
	div {
		display: flex;
		flex: 1;
		align-items: center;
		column-gap: var(--spacing-unit-s2);
	}
	
	label {
		font-size: var(--font-size-default);
		line-height: var(--line-height-default);
		color: hsl(var(--black-color));
	}
	
	a {
		font-size: var(--font-size-default);
		line-height: var(--line-height-default);
	}
`;

const Or = styled.div`
	font-size: var(--font-size-default);
	line-height: var(--line-height-default);
	color: hsl(var(--black-color));
	display: flex;
	align-items: center;
	column-gap: var(--spacing-unit-s2);
	
	&::before, &::after {
		height: 1px;
		background-color: hsl(var(--light-gray-color));
		display: block;
		flex: 1;
		content: "";
	}
`;

const ThirdParty = styled.div`
	padding: 0 var(--spacing-unit-default);
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s2);
`;

const Button = styled.button`
	font-size: var(--font-size-default);
	line-height: var(--line-height-default);
	color: hsl(var(--black-color));
	padding: 8px var(--spacing-unit-s1);
	background-color: transparent;
	border: 1px solid hsl(var(--gray-color));
	border-radius: var(--border-radius);
	cursor: pointer;
	outline: none;
	user-select: none;
	
	@media (hover: hover) {
		&:hover {
			box-shadow: var(--natural-shadow);
		}
	}
	
	&:focus {
		outline: none;
		box-shadow: var(--natural-shadow);
	}
	
	svg {
		height: var(--font-size-l2);
		float: left;
	}
`;

const Question = styled.div`
	font-size: var(--font-size-default);
	line-height: var(--line-height-default);
	color: hsl(var(--black-color));
	text-align: center;
	padding: 0 var(--spacing-unit-default);
`;

const Accept = styled.div`
	font-size: var(--font-size-s1);
	line-height: var(--line-height-s1);
	color: hsl(var(--dark-gray-color));
	text-align: center;
	padding: 0 var(--spacing-unit-default);
`;

// #endregion

const SignIn = () => {
	const { ENDPOINT } = (window as any).env as Types;
	const {
		REACT_APP_EMAIL_MAXLENGTH,
		REACT_APP_PASSWORD_MAXLENGTH
	} = (process as any).env as Types;
	
	const { state, dispatch } = useContext(dataContext) as Types;
	const {
		code: languageCode,
		head: languageHead,
		text: languageText
	} = state.language as Types;
	
	const navigate = useNavigate();
	
	const formRef = useRef<HTMLFormElement>(null);
	const emailRef = useRef<HTMLInputElement>(null);
	const passwordRef = useRef<HTMLInputElement>(null);
	const checkboxRef = useRef<HTMLInputElement>(null);
	
	// #region checkInput
	
	const checkInput = (event: React.FormEvent<HTMLFormElement>) => {
		const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		const email = emailRef.current!.value;
		const password = passwordRef.current!.value;
		const submit = event.currentTarget.querySelector('input[type=submit]');
		
		if (email && pattern.test(email) && password) {
			if (submit!.hasAttribute('disabled')) submit!.removeAttribute('disabled');
		} else {
			if (!submit!.hasAttribute('disabled')) submit!.setAttribute('disabled', '');
		}
	}
	
	// #endregion
	
	// #region submitSignIn
	
	const submitSignIn = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		
		const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		const email = emailRef.current!.value;
		const password = passwordRef.current!.value;
		const checkbox = checkboxRef.current!.checked;
		const submit = event.currentTarget.querySelector('input[type=submit]');
		
		if (email && pattern.test(email) && password) {
			const query = `mutation SignIn($input: UserInput) { signIn(input: $input) { success message status access_token refresh_token } }`;
			
			fetch(`${ ENDPOINT }`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json'
				},
				body: JSON.stringify({
					query,
					variables: {
						input: {
							email,
							password
						}
					}
				})
			})
			.then(response => response.json())
			.then(result => {
				const {
					success,
					message,
					status,
					access_token,
					refresh_token
				} = result.data.signIn as {
					success: boolean;
					message: string;
					status: string;
					access_token: string;
					refresh_token: string;
				};
				
				if (success) {
					formRef.current?.reset();
					submit!.setAttribute('disabled', '');
					
					switch(status) {
						case 'active':
							const type: string = (!checkbox) ? 'session' : 'local';
							useToken.set(type, access_token, refresh_token);
							
							dispatch({
								type: 'set_auth',
								payload: useToken.payload(access_token)
							});
							
							navigate(`/${ languageCode }/account`, { replace: true });
							
							break;
						case 'verify':
							/*navigate(`/${ languageCode }/create-account/verify`, { replace: true });*/
							
							break;
						case 'block':
							/*block*/
							
							break;
						default:
							useHandler.console(message);
					}
				} else {
					useHandler.console(message);
				}
			})
			.catch(error => useHandler.console(error));
		}
	}
	
	// #endregion
	
	// #region clickSignIn
	
	const clickSignIn = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		/*console.log(`Sign in = ${ event.currentTarget.name }`);*/
	}
	
	// #endregion
	
	return (
		<>
			<Helmet>
				<title>{ languageHead.sign_in.title }</title>
				<meta name='description' content={ languageHead.sign_in.description } />
			</Helmet>
			<Main>
				<Head>
					<div>{ `${ languageText.hello }!` }</div>
					<div>{ languageText.sign_in_to_continue }</div>
				</Head>
				<Form ref={ formRef } onInput={ checkInput } onSubmit={ submitSignIn }>
					<Input>
						<Label htmlFor='email' className='bophloi' $icon='mail' />
						<input type='email' id='email' ref={ emailRef } title={ languageText.email } maxLength={ REACT_APP_EMAIL_MAXLENGTH } placeholder={ languageText.email } className='input' />
					</Input>
					<Input>
						<Label htmlFor='password' className='bophloi' $icon='lock' />
						<input type='password' id='password' ref={ passwordRef } title={ languageText.password } maxLength={ REACT_APP_PASSWORD_MAXLENGTH } placeholder={ languageText.password } className='input' />
						<Eye type='button' title={ languageText.show_password } className='button-icon bophloi eye-closed' onClick={ event => useHandler.eye(event, [languageText.hide_password, languageText.show_password], passwordRef) } />
					</Input>
					<Option>
						<div>
							<input type='checkbox' id='checkbox' ref={ checkboxRef } title={ languageText.keep_me_signed_in } className='checkbox' />
							<label htmlFor='checkbox'>{ languageText.keep_me_signed_in }</label>
						</div>
						<Link to={ `/${ languageCode }/sign-in/reset-password` } title={ languageText.reset_password } className='link'>{ languageText.reset_password }</Link>
					</Option>
					<input type='submit' value={ languageText.sign_in } title={ languageText.sign_in } className='button-primary' disabled />
				</Form>
				<Or>{ languageText.or }</Or>
				<ThirdParty>
					<Button name='facebook' title={ `${ languageText.sign_in_with } Facebook` } onClick={ clickSignIn }>
						<svg id="facebook" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
							<path d="M13.63,9.86V12H16.7l-.46,3.1H13.63v7.78A11,11,0,0,1,12,23a10.55,10.55,0,0,1-1.79-.15V15.13H7.47V12h2.67V10.45a8.16,8.16,0,0,1,.23-2.56A4,4,0,0,1,12.1,5.67a3.84,3.84,0,0,1,.8-.36,5.33,5.33,0,0,1,1.95-.23,10.15,10.15,0,0,1,2,.43V8.18H15.58a2,2,0,0,0-1.38.33A1.71,1.71,0,0,0,13.63,9.86Z" style={{ fill: "#fff" }} />
							<path d="M12,1a11,11,0,0,0-1.79,21.84V15.13H7.47V12h2.67V10.45a8.16,8.16,0,0,1,.23-2.56A4,4,0,0,1,12.1,5.67a3.84,3.84,0,0,1,.8-.36,5.33,5.33,0,0,1,1.95-.23,10.15,10.15,0,0,1,2,.43V8.18H15.58a2,2,0,0,0-1.38.33,1.71,1.71,0,0,0-.57,1.35V12H16.7l-.46,3.1H13.63v7.77A11,11,0,0,0,12,1Z" style={{ fill: "#0866ff" }} />
						</svg>
						{ `${ languageText.sign_in_with } Facebook` }
					</Button>
					<Button name='google' title={ `${ languageText.sign_in_with } Google` } onClick={ clickSignIn }>
						<svg id="google" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
							<path d="M22.5,12a10.508,10.508,0,0,1-3.465,7.789L15.79,17.317a6.307,6.307,0,0,0,2.6-3.332h-6V10.043h9.937A11.112,11.112,0,0,1,22.5,12Z" style={{ fill: "#4285f4" }} />
							<path d="M19.035,19.789a10.208,10.208,0,0,1-2.577,1.718,10.494,10.494,0,0,1-11.989-2.2,10.164,10.164,0,0,1-1.8-2.5l3.694-2.9a6.325,6.325,0,0,0,6.023,4.4,6.443,6.443,0,0,0,3.408-.993Z" style={{ fill: "#34a853" }} />
							<path d="M6.387,9.948a6.292,6.292,0,0,0-.028,3.961l-3.694,2.9A10.5,10.5,0,0,1,2.7,7.122L6.387,9.938Z" style={{ fill: "#fbbc05" }} />
							<path d="M19.169,4.335,16.42,7.084A6.339,6.339,0,0,0,6.387,9.938L2.7,7.122A10.32,10.32,0,0,1,5.022,4.154a10.491,10.491,0,0,1,14.147.181Z" style={{ fill: "#ea4335" }} />
						</svg>
						{ `${ languageText.sign_in_with } Google` }
					</Button>
					<Button name='line' title={ `${ languageText.sign_in_with } Line` } onClick={ clickSignIn }>
						<svg id="line" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
							<path d="M1,12a11,11,0,1,0,22,0a11,11,0,1,0,-22,0" style={{ fill: "#22ba4f" }} />
							<path d="M12.14,5.23a7.86,7.86,0,0,1,6.4,3c.13.19.24.38.35.57a5.2,5.2,0,0,1,.26,4.33,8.14,8.14,0,0,1-2.54,3.19,34.19,34.19,0,0,1-3.44,2.53c-.36.23-2,1.31-1.87.17,0-.2.11-.39.14-.59.06-.37,0-.86-.37-1a2.18,2.18,0,0,0-.51-.09c-3-.35-6-2.56-6.07-5.76-.08-3,2.35-5.31,5.06-6A9.73,9.73,0,0,1,12.14,5.23Z" style={{ fill: "#fff" }} />
							<path d="M6.89,9.88L7.7,9.88L7.7,12.51L9.22,12.51L9.22,13.26L6.89,13.26L6.89,9.88z" style={{ fill: "#22ba4f" }} />
							<path d="M9.77,9.88h0.77v3.38H9.77Z" style={{ fill: "#22ba4f" }} />
							<path d="M11.28,9.88L11.28,13.26L12.03,13.26L12.03,11.14L13.38,13.26L14.13,13.26L14.13,9.88L13.37,9.88L13.37,12.04L12.03,9.88L11.28,9.88z" style={{ fill: "#22ba4f" }} />
							<path d="M14.87,9.88L14.87,13.26L17.16,13.26L17.16,12.57L15.69,12.57L15.69,11.94L17.16,11.94L17.16,11.24L15.69,11.24L15.69,10.63L17.16,10.63L17.16,9.88L14.87,9.88z" style={{ fill: "#22ba4f" }} />
						</svg>
						{ `${ languageText.sign_in_with } Line` }
					</Button>
				</ThirdParty>
				<Question>
					{ `${ languageText.dont_have_an_account }? ` }
					<Link to={ `/${ languageCode }/create-account` } title={ languageText.create_account } className='link'>{ languageText.create_account }</Link>
				</Question>
				<Accept>
					{ `เมื่อลงชื่อเข้าใช้ แสดงว่าคุณยอมรับ ` }
					<Link to={ `/${ languageCode }/terms-of-use` } title={ languageText.terms_of_use } className='link'>{ languageText.terms_of_use }</Link>
					{ ` และ ` }
					<Link to={ `/${ languageCode }/privacy-policy` } title={ languageText.privacy_policy } className='link'>{ languageText.privacy_policy }</Link>
					{ ` ของเราแล้ว` }
				</Accept>
			</Main>
			<div className='border' />
		</>
	);
}

export default SignIn;