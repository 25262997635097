import { Types } from '../config/types';
import { useContext } from 'react';
import { dataContext } from '../App';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

// #region Styled

const Main = styled.main`
	padding: var(--spacing-unit-default) 19px;
	flex: 1;
`;

// #endregion

const CookiesPolicy = () => {
	const { state } = useContext(dataContext) as Types;
	const {
		code: languageCode,
		name: languageName,
		head: languageHead
	} = state.language as Types;
	
	const createMarkup = () => {
		const content: string = require(`../utils/languages/cookies-policy-${ languageCode }`).default;
		const object: { [index: string]: string } = {
			'{ domain }': window.location.origin,
			'{ name }': languageName
		}
		const regex = new RegExp(Object.keys(object).join('|'), 'g');
		const update = content.replace(regex, match => object[match]);
		
		return {__html: update};
	}
	
	return (
		<>
			<Helmet>
				<title>{ languageHead.cookies_policy.title }</title>
				<meta name='description' content={ languageHead.cookies_policy.description } />
			</Helmet>
			<Main>
				<div className='content' dangerouslySetInnerHTML={ createMarkup() }></div>
			</Main>
			<div className='border' />
		</>
	);
}

export default CookiesPolicy;