import { Types } from '../config/types';
import { useContext } from 'react';
import { dataContext } from '../App';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import ProductList from '../components/ProductList';
import ProductDescription from '../components/ProductDescription';

// #region Styled

const List = styled.main`
	padding: var(--spacing-unit-default) 0;
	flex: 1;
`;

const Description = styled.main`
	max-width: inherit;
	padding-bottom: var(--spacing-unit-default);
	flex: 1;
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-default);
`;

// #endregion

const Products = () => {
	const { state } = useContext(dataContext) as Types;
	const { text: languageText } = state.language as Types;
	const params = useParams();
	
	return (
		<>
			{ !params.id
				? <>
					<List>
						<ProductList helmet={ true } title={ languageText.product_list } option='filter' />
					</List>
				</>
				: <>
					<Description>
						<ProductDescription helmet={ true } />
					</Description>
				</>
			}
			<div className='border' />
		</>
	);
}

export default Products;