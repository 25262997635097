import { Types } from '../config/types';

const { APPLICATION_MODE } = (window as any).env as Types;

const useHandler = {
	
	// #region console
	
	console: (err: unknown) => {
		(APPLICATION_MODE === 'development') && console.error(err);
	},
	
	// #endregion
	
	// #region history
	
	history: () => {
		const result = (window.history.length > 1) ? true : false;
		
		return result;
	},
	
	// #endregion
	
	// #region image
	
	image: (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
		event.currentTarget.style.display = 'none';
	},
	
	// #endregion
	
	// #region eye
	
	eye: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, title: string[], passwordRef: React.RefObject<HTMLInputElement>) => {
		const password = passwordRef.current!;
		const eye = event.currentTarget;
		
		if (password.type === 'password') {
			password.type = 'text';
			eye.title = title[0];
			eye.classList.remove('eye-closed');
			eye.classList.add('eye');
		} else {
			password.type = 'password';
			eye.title = title[1];
			eye.classList.remove('eye');
			eye.classList.add('eye-closed');
		}
	}
	
	// #endregion
	
}

export default useHandler;