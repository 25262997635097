import { Types } from '../config/types';

const { APPLICATION_MODE } = (window as any).env as Types;
const security: string = (APPLICATION_MODE === 'production') ? 'secure; samesite=Strict' : '';

const useCookie = {
	
	// #region set
	
	set: (name: string, value: string, expire: number) => {
		const date = new Date();
		date.setTime(date.getTime()+(expire*24*60*60*1000));
		const expires = `expires=${ date.toUTCString() }`;
		
		document.cookie = `${ name }=${ value }; ${ expires }; path=/; ${ security }`;
	},
	
	// #endregion
	
	// #region get
	
	get: (name: string) => {
		const text = `${ name }=`;
		const array = document.cookie.split(';');
		let result: string = '';
		
		for(let i = 0; i < array.length; i++) {
			const items = array[i].trim();
			
			if (items.indexOf(text) === 0) {
				result = items.substring(text.length);
				
				break;
			}
		}
		
		return result;
	},
	
	// #endregion
	
	// #region delete
	
	delete: (name: string) => {
		useCookie.set(name, '', 0);
	}
	
	// #endregion
	
}

export default useCookie;