import { Types } from '../config/types';
import { useContext } from 'react';
import { dataContext } from '../App';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import Authentication from '../components/Authentication';
import Authorization from '../components/Authorization';

// #region Styled

const Main = styled.main`
	padding: var(--spacing-unit-default) var(--spacing-unit-s1);
	flex: 1;
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-default);
`;

const Head = styled.div`
	font-size: var(--font-size-l1);
	font-weight: bold;
	line-height: var(--line-height-l1);
	color: hsl(var(--black-color));
	text-align: center;
`;

// #endregion

const Cart = () => {
	const { state } = useContext(dataContext) as Types;
	const {
		head: languageHead,
		text: languageText
	} = state.language as Types;
	
	const { success: authSuccess, role: authRole } = state.auth as Types;
	
	return (
		<>
			<Helmet>
				<title>{ languageHead.cart.title }</title>
				<meta name='description' content={ languageHead.cart.description } />
			</Helmet>
			{ !authSuccess
				? <Authentication />
				: authRole === 'member'
					? <Main>
						<Head>{ languageText.cart }</Head>
					</Main>
					: <Authorization />
			}
			<div className='border' />
		</>
	);
}

export default Cart;