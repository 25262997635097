import { Types } from '../config/types';
import { useContext } from 'react';
import { dataContext } from '../App';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import useToken from '../hooks/useToken';
import Authentication from '../components/Authentication';

// #region Styled

const Main = styled.main`
	padding: var(--spacing-unit-default) 0;
	flex: 1;
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-default);
`;

const SignOut = styled.div`
	padding: 0 19px;
	display: flex;
	flex-direction: column;
`;

// #endregion

const Account = () => {
	const { state, dispatch } = useContext(dataContext) as Types;
	const {
		code: languageCode,
		head: languageHead,
		text: languageText
	} = state.language as Types;
	
	const { success: authSuccess } = state.auth as Types;
	
	const navigate = useNavigate();
	
	// #region clickSignOut
	
	const clickSignOut = () => {
		useToken.remove();
		
		dispatch({
			type: 'set_auth',
			payload: {
				success: false
			}
		});
		
		navigate(`/${ languageCode }/sign-in`, { replace: true });
	}
	
	// #endregion
	
	return (
		<>
			<Helmet>
				<title>{ languageHead.account.title }</title>
				<meta name='description' content={ languageHead.account.description } />
			</Helmet>
			{ !authSuccess
				? <Authentication />
				: <Main>
					<SignOut>
						<button title={ languageText.sign_out } onClick={ clickSignOut } className='button-secondary'>{ languageText.sign_out }</button>
					</SignOut>
				</Main>
			}
			<div className='border'></div>
		</>
	);
}

export default Account;