import { Types } from '../config/types';
import { useContext, useRef, useEffect } from 'react';
import { dataContext } from '../App';
import { Helmet } from 'react-helmet-async';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useHandler from '../hooks/useHandler';

// #region Styled

const Top = styled.div`
	--transition-duration: 0.3s;
	
	width: 100%;
	max-width: inherit;
	padding: var(--spacing-unit-s1) var(--spacing-unit-s1) 0;
	border-bottom: 1px solid transparent;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	z-index: 1;
	transition:
		padding var(--transition-duration),
		background-color var(--transition-duration);
	
	&.on-scroll {
		padding: 7.5px 8.5px;
		background-color: hsl(var(--white-color));
		border-bottom: 1px solid hsl(var(--light-gray-color));
	}
	
	div {
		display: flex;
		column-gap: var(--spacing-unit-s1);
	}
`;

const Button = styled.button<{ $icon?: string; }>`
	&::before {
		content: var(--${ props => props.$icon });
	}
	
	&.button-event::before, &.button-icon::before {
		${ props => {
			switch(props.$icon) {
				case 'left-arrow':
					return `
						position: relative;
						left: -1px;
					`;
				case 'share':
					return `
						position: relative;
						top: -1px;
					`;
				case 'favorite':
					return `
						position: relative;
						top: 1px;
					`;
			}
		}}
	}
`;

const Carousel = styled.div`
	background-color: hsl(var(--light-gray-color));
	aspect-ratio: 1;
	position: relative;
	
	ul {
		list-style: none;
		padding-inline: unset;
	}
`;

const Image = styled.ul`
	img {
		height: auto;
		width: 100%;
		display: block;
	}
`;

const Dot = styled.ul`
	display: flex;
	justify-content: center;
	column-gap: var(--spacing-unit-s3);
	position: absolute;
	bottom: var(--spacing-unit-s1);
	left: 50%;
	transform: translate(-50%, 0);
	
	li {
		height: var(--spacing-unit-s3);
		width: var(--spacing-unit-s3);
		border-radius: 100%;
		background-color: hsla(var(--gray-color), 0.75);
	}
	
	.selected {
		background-color: hsl(var(--primary-color));
	}
`;

const Wrapper = styled.div`
	padding: 0 var(--spacing-unit-s1);
	flex: 1;
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s1);
`;

const Information = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s2);
`;

const Item = styled.div`
	font-size: var(--font-size-s1);
	line-height: var(--line-height-s1);
	color: hsl(var(--dark-gray-color));
`;

const Title = styled.h1`
	font-size: var(--font-size-l1);
	line-height: var(--line-height-l1);
	color: hsl(var(--black-color));
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
`;

const Price = styled.div<{ $name?: string; }>`
	--line-height: 33px;
	
	display: flex;
	align-items: center;
	column-gap: var(--spacing-unit-s3);
	
	div:first-child {
		font-size: 21px;
		font-weight: 600;
		line-height: var(--line-height);
		display: flex;
		align-items: center;
		
		${ props => {
			switch(props.$name) {
				case 'special-price':
					return 'color: hsl(var(--bright-red-color));';
				default:
					return 'color: hsl(var(--bright-primary-color));';
			}
		}}
		
		span {
			font-size: var(--font-size-default);
			font-weight: normal;
		}
		
		span:first-child {
			line-height: var(--line-height-default);
			position: relative;
			top: -1px;
		}
		
		span:last-child {
			margin-left: var(--spacing-unit-s4);
		}
	}
`;

const Normal = styled.div`
	font-size: var(--font-size-default);
	line-height: var(--line-height);
	color: hsl(var(--dark-gray-color));
	text-decoration: line-through;
`;

const Tag = styled.div<{ $name?: string; }>`
	font-size: var(--font-size-s1);
	line-height: var(--line-height-s1);
	color: hsl(var(--white-color));
	padding: var(--spacing-box-unit);
	border-radius: var(--border-radius);
	
	${ props => {
		switch(props.$name) {
			case 'special-price':
				return 'background-color: hsl(var(--red-color));';
		}
	}}
`;

const Stock = styled.div`
	font-size: var(--font-size-default);
	line-height: var(--line-height-default);
	color: hsl(var(--dark-gray-color));
	
	span {
		text-decoration: underline;
	}
`;

const Option = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s1);
`;

const Group = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s1);
	
	div:first-child {
		font-size: var(--font-size-default);
		line-height: var(--line-height-default);
		color: hsl(var(--black-color));
	}
	
	span {
		color: hsl(var(--dark-gray-color));
	}
`;

const Description= styled.div`
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s2);
	
	div {
		font-size: var(--font-size-default);
		line-height: var(--line-height-default);
	}
	
	div:first-child {
		font-weight: bold;
		color: hsl(var(--black-color));
	}
	
	div:last-child {
		color: hsl(var(--dark-gray-color));
	}
`;

const Bottom = styled.div`
	width: 100%;
	max-width: inherit;
	background-color: hsl(var(--white-color));
	border-top: 1px solid hsl(var(--light-gray-color));
	position: fixed;
	bottom: 0;
	
	div:first-child {
		padding: 4px var(--spacing-unit-s1);
		display: flex;
		align-items: center;
		column-gap: var(--spacing-unit-s2);
	}
	
	button {
		flex: 1;
	}
`;

// #endregion

const ProductDescription = (props: { helmet: boolean }) => {
	const { state } = useContext(dataContext) as Types;
	const { 
		code: languageCode,
		text: languageText
	} = state.language as Types;
	const params = useParams();
	const navigate = useNavigate();

	

	const array = params.id!.split('-ite.');
	console.log(array[1]);
	


	const actionRef = useRef<HTMLDivElement>(null);
	const arrayRef = useRef<HTMLButtonElement[]>([]);
	const buttonRefs = (element: HTMLButtonElement) => arrayRef.current.push(element);
	const lineRef = useRef<HTMLDivElement>(null);
	const targetRef = useRef<HTMLDivElement>(null);
	
	const backward = () => {
		if (useHandler.history()) {
			navigate(-1);
		} else {
			navigate('/');
		}
	}
	
	useEffect(() => {
		const options = {
			rootMargin: '-54px 0px 0px 0px'
		}
		
		const observer = new IntersectionObserver(entries => {
			actionRef.current?.classList.toggle('on-scroll', !entries[0].isIntersecting);
			
			if (!entries[0].isIntersecting) {
				arrayRef.current.forEach(element => {
					element?.classList.remove('button-event');
					element?.classList.add('button-icon');
				});
				lineRef.current?.classList.remove('line');
				lineRef.current?.classList.add('line-transparent');
			} else {
				arrayRef.current.forEach(element => {
					element?.classList.remove('button-icon');
					element?.classList.add('button-event');
				});
				lineRef.current?.classList.remove('line-transparent');
				lineRef.current?.classList.add('line');
			}
		}, options);
		
		observer.observe(targetRef.current!);
	}, []);
	
	return (
		<>
			{ props.helmet &&
				<Helmet>
					<title>หัวข้อสินค้าที่มีขนาดความยาวไม่เกิน 55-75 ตัวอักษร ขนาด - BOKOus</title>
					<meta name='description' content='หัวข้อสินค้าที่มีขนาดความยาวไม่เกิน 55-75 ตัวอักษร ขนาด...' />
					<style>{`.top-navigation, .fill-top, .bottom-navigation { display: none; }`}</style>
				</Helmet>
			}
			<Top ref={ actionRef }>
				<Button type='button' ref={ buttonRefs } title={ languageText.backward } className='button-event bophloi' $icon='left-arrow' onClick={ backward }></Button>
				<div>
					<Button type='button' ref={ buttonRefs } title={ languageText.share } className='button-event bophloi' $icon='share'></Button>
					<Button type='button' ref={ buttonRefs } title={ languageText.cart } className='button-event bophloi' $icon='cart' onClick={ () => navigate(`/${ languageCode }/cart`) }></Button>
					<Button type='button' ref={ buttonRefs } title={ languageText.favorite } className='button-event bophloi' $icon='favorite'></Button>
				</div>
			</Top>
			<div ref={ targetRef }>
				<Carousel>
					<Image>
						<li><img src='/images/products-item/5923773.webp' alt='รูปภาพสินค้า' onError={ useHandler.image } /></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
					</Image>
					<Dot>
						<li className='selected'></li>
						<li></li>
						<li></li>
						<li></li>
						<li></li>
					</Dot>
				</Carousel>
				<div ref={ lineRef } className='line' />
			</div>
			<Wrapper>
				<Information>
					<Item>รหัสสินค้า : 1234567</Item>
					<Title>หัวข้อสินค้าที่มีขนาดความยาวไม่เกิน 55-75 ตัวอักษร ขนาด 19px ตัวหนา สีดำ</Title>
					<Price $name='special-price'>
						<div><span>฿</span>199<span>/ชิ้น</span></div>
						<Normal>{ `${ languageText.list_price } ฿299` }</Normal>
						<Tag $name='special-price'>-฿100</Tag>
					</Price>
					<Stock>{ languageText.in_stock }</Stock>
				</Information>
				<div className='line' />
				<Option>
					<Group>
						<div><b>สี</b> : <span>ข้อความ</span></div>
						<div className='option-color'>
							<a></a>
							<a></a>
							<a></a>
						</div>
					</Group>
					<Group>
						<div><b>ขนาด</b> : <span>ข้อความ</span></div>
						<div className='option-size'>
							<a>24</a>
							<a>36</a>
							<a>48</a>
						</div>
					</Group>
				</Option>
				<div className='line' />
				<Description>
					<div>รายละเอียดสินค้า</div>
					<div>รายละเอียดสินค้า...</div>
				</Description>
			</Wrapper>
			<Bottom>
				<div>
					<button type='button' title={ languageText.add_to_cart } className='button-ghost'>{ languageText.add_to_cart }</button>
					<button type='button' title={ languageText.buy_now } className='button-primary'>{ languageText.buy_now }</button>
				</div>
				<div className='safearea-bottom' />
			</Bottom>
		</>
	);
}

export default ProductDescription;