import { Types } from '../config/types';
import useHandler from './useHandler';
import useCookie from './useCookie';

const useLanguage = {
	
	// #region lang
	
	lang: (name: string) => { /* /xx/... */
		const result = /^\/[a-z]{2}\/.+$/.test(name) ? name.match(/[a-z]{2}/)![0] : null;
		
		return result;
	},
	
	// #endregion
	
	// #region get
	
	get: (lang: string, dlang: string, name: string, expire: number) => {
		let result: object;
		let object = {} as Types;
		
		try {
			object = JSON.parse(useCookie.get(name));
		} catch(err) {
			useHandler.console(err);
		}
		
		if (!lang) {
			result = require(`../utils/languages/${ object.lang ? object.lang : dlang }`);
			
			if (dlang !== object.lang && object.lang) document.documentElement.setAttribute('lang', object.lang);
		} else {
			try {
				result = require(`../utils/languages/${ lang }`);
				
				if (dlang !== lang) document.documentElement.setAttribute('lang', lang);
				
				if (lang !== object.lang) {
					const update = { ...object, lang: lang };
					useCookie.set(name, JSON.stringify(update), expire);
				}
			} catch(err) {
				useHandler.console(err);
				
				result = require(`../utils/languages/${ dlang }`);
			}
		}
		
		return result;
	}
	
	// #endregion
	
}

export default useLanguage;