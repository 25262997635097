import { Types } from '../config/types';
import { useContext } from 'react';
import { dataContext } from '../App';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

// #region Styled

const Navigation = styled.nav`
	width: 100%;
	max-width: inherit;
	background-color: hsl(var(--white-color));
	border-top: 1px solid hsl(var(--light-gray-color));
	position: fixed;
	bottom: 0;
`;

const Wrapper = styled.div`
	padding: 5px 0;
	display: flex;
	
	div {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1;
	}
`;

const NavLinks = styled(NavLink)<{ $icon?: string; }>`
	font-size: 10px;
	line-height: var(--line-height-s2);
	color: hsl(var(--black-color));
	min-width: 42px;
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 3px;
	
	&::before {
		content: var(--${ props => props.$icon });
		font-size: var(--font-size-l2);
	}
	
	&.active {
		color: hsl(var(--primary-color));
		
		&::before {
			content: var(--${ props => props.$icon }-solid);
		}
	}
`;

// #endregion

const BottomNavigation = () => {
	const { state } = useContext(dataContext) as Types;
	const {
		code: languageCode,
		cat: languageCat,
		text: languageText
	} = state.language as Types;
	
	const {
		success: authSuccess,
		role: authRole
	} = state.auth as Types;
	
	return (
		<Navigation className='bottom-navigation'>
			<Wrapper>
				<div>
					<NavLinks to='/' title={ languageText.home } hrefLang={ languageCode } className='link-icon bophloi' $icon='home'>{ languageText.home }</NavLinks>
				</div>
				<div>
					<NavLinks to={ `/${ languageCode }/products` } title={ languageText.products } className='link-icon bophloi' $icon='product'>{ languageText.products }</NavLinks>
				</div>
				<div>
					<NavLinks to={ `/${ languageCode }/promotions` } title={ languageText.promotions } className='link-icon bophloi' $icon='promotion'>{ languageText.promotions }</NavLinks>
				</div>
				<div>
					{ authSuccess && (authRole === 'staff' || authRole ==='owner')
						? <NavLinks to={ `/${ languageCode }/order` } title={ languageText.order } className='link-icon bophloi' $icon='order'>{ languageText.order }</NavLinks>
						: <NavLinks to={ `/${ languageCode }/cart` } title={ languageText.cart } className='link-icon bophloi' $icon='cart'>{ languageText.cart }</NavLinks>
					}
				</div>
				<div>
					{ authSuccess
						? <>
							{(() => {
								switch(authRole) {
									case 'member':
										return (
											<NavLinks to={ `/${ languageCode }/account` } title={ languageText.account } className='link-icon bophloi' $icon='member'>{ languageText.member }</NavLinks>
										);
									case 'staff':
										return (
											<NavLinks to={ `/${ languageCode }/account` } title={ languageText.account } className='link-icon bophloi' $icon='cat'>{ languageCat.sugar }</NavLinks>
										);
									case 'owner':
										return (
											<NavLinks to={ `/${ languageCode }/account` } title={ languageText.account } className='link-icon bophloi' $icon='cat'>{ languageCat.boko }</NavLinks>
										);
								}
							})()}
						</>
						: <NavLinks to={ `/${ languageCode }/sign-in` } title={ languageText.sign_in } className='link-icon bophloi' $icon='user'>{ languageText.sign_in }</NavLinks>
					}
				</div>
			</Wrapper>
			<div className='safearea-bottom' />
		</Navigation>
	);
}

export default BottomNavigation;