import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
	// #region root style
	
	:root {
		--font-size-l2: 24px;
		--font-size-l1: 19px;
		--font-size-default: 15px;
		--font-size-s1: 12px;
		--font-size-s2: 9px;
		
		--line-height-l2: 39px;
		--line-height-l1: 31px;
		--line-height-default: 24px;
		--line-height-s1: 19px;
		--line-height-s2: 15px;
		
		--spacing-unit-l1: 39px;
		--spacing-unit-default: 24px;
		--spacing-unit-s1: 15px;
		--spacing-unit-s2: 9px;
		--spacing-unit-s3: 6px;
		--spacing-unit-s4: 4px;
		
		--primary-color: 260, 65%, 50%;
		--secondary-color: 260, 65%, 95%;
		--red-color: 0, 65%, 50%;
		--yellow-color: 60, 65%, 50%;
		--green-color: 120, 65%, 50%;
		--bright-primary-color: 260, 95%, 50%;
		--bright-red-color: 0, 95%, 50%;
		--black-color: 260, 65%, 10%;
		--dark-gray-color: 260, 3%, 45%;
		--gray-color: 260, 3%, 75%;
		--light-gray-color: 260, 3%, 95%;
		--white-color: 0, 0%, 100%;
		
		--primary-hover-color: 260, 65%, 35%;
		--primary-press-color: 260, 65%, 20%;
		--primary-disable-color: 260, 65%, 80%;
		--dark-gray-hover-color: 260, 3%, 30%;
		--dark-gray-press-color: 260, 3%, 15%;
		--dark-gray-disable-color: 260, 3%, 75%;
		
		--border-radius: 4px;
		--natural-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.1), 0 6px 12px hsla(0, 0%, 0%, 0.08);
		--spacing-box-unit: 2px 6px;
		--spacing-label-unit: 1.5px 5px;
	}
	
	* {
		box-sizing: border-box;
	}
	
	html, body, #root {
		height: 100%;
	}
	
	body {
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		margin: 0;
	}
	
	#root {
		max-width: 414px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		flex-direction: column;
	}
	
	code {
		font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
	}
	
	.fill-top, .fill-bottom {
		padding-top: 53px;
	}
	
	.safearea-bottom {
		height: calc(env(safe-area-inset-bottom) - 5px);
	}
	
	// #endregion
	
	// #region reset style
	
	ul, ol {
		margin-block: auto;
	}
	
	blockquote {
		margin-block: auto;
		margin-inline: auto;
	}
	
	h1, h2, h3, h4, h5, h6, P {
		margin-block: auto;
	}
	
	a {
		text-decoration: none;
	}
	
	input, button, select {
		font-family: inherit;
		appearance: none;
		padding: 0;
		margin: 0;
		border: none;
	}
	
	option {
		padding-block: auto;
		min-block-size: auto;
		padding-inline: unset;
	}
	
	// #endregion
	
	// #region link, button style
	
	.link {
		text-decoration: underline;
		color: hsl(var(--primary-color));
	}
	
	@media (hover: hover) {
		.link-primary:hover {
			text-decoration: underline;
		}
		
		.link-icon:hover {
			color: hsl(var(--primary-color));
		}
	}
	
	.link-primary:focus {
		text-decoration: underline;
		outline: none;
	}
	
	.link-icon:focus {
		color: hsl(var(--primary-color));
		outline: none;
	}
	
	.button-primary, .button-secondary, .button-ghost, .button-event, .button-icon {
		cursor: pointer;
		outline: none;
		user-select: none;
	}
	
	.button-primary, .button-secondary {
		font-size: var(--font-size-default);
		font-weight: bold;
		line-height: var(--line-height-default);
		color: hsl(var(--white-color));
		padding: var(--spacing-unit-s2) var(--spacing-unit-s1);
		border-radius: var(--border-radius);
	}
	
	.button-primary {
		background-color: hsl(var(--primary-color));
	}
	
	.button-secondary {
		background-color: hsl(var(--dark-gray-color));
	}
	
	.button-ghost {
		font-size: var(--font-size-default);
		font-weight: bold;
		line-height: var(--line-height-default);
		color: hsl(var(--primary-color));
		background-color: transparent;
		padding: var(--spacing-unit-s2) var(--spacing-unit-s1);
		border: 1px solid hsl(var(--primary-color));
		border-radius: var(--border-radius);
	}
	
	.button-event {
		font-size: var(--font-size-l1);
		color: hsl(var(--white-color));
		background-color: hsla(var(--dark-gray-color), 0.75);
		padding: var(--spacing-unit-s2);
		border-radius: 100%;
	}
	
	.button-icon {
		font-size: var(--font-size-l2);
		color: hsl(var(--black-color));
		background-color: transparent;
		padding: 6.5px;
		border-radius: 100%;
	}
	
	@media (hover: hover) {
		.button-primary:hover {
			background-color: hsl(var(--primary-hover-color));
		}
		
		.button-secondary:hover, .button-event:hover {
			background-color: hsl(var(--dark-gray-hover-color));
		}
		
		.button-ghost:hover {
			color: hsl(var(--primary-hover-color));
			border-color: hsl(var(--primary-hover-color));
		}
	}
	
	.button-primary:focus {
		background-color: hsl(var(--primary-hover-color));
	}
	
	.button-secondary:focus, .button-event:focus {
		background-color: hsl(var(--dark-gray-hover-color));
	}
	
	.button-ghost:focus {
		color: hsl(var(--primary-hover-color));
		border-color: hsl(var(--primary-hover-color));
	}
	
	.button-primary:active {
		background-color: hsl(var(--primary-press-color));
	}
	
	.button-secondary:active, .button-event:active {
		background-color: hsl(var(--dark-gray-press-color));
	}
	
	.button-ghost:active {
		color: hsl(var(--primary-press-color));
		border-color: hsl(var(--primary-press-color));
	}
	
	.button-primary:disabled, .button-secondary:disabled {
		cursor: auto;
	}
	
	.button-primary:disabled {
		background-color: hsl(var(--primary-disable-color));
	}
	
	.button-secondary:disabled {
		background-color: hsl(var(--dark-gray-disable-color));
	}
	
	// #endregion
	
	// #region select style
	
	.select-primary {
		font-size: var(--font-size-s1);
		font-weight: bold;
		line-height: var(--line-height-s1);
		color: hsl(var(--black-color));
		padding: 8px 12px;
		background-color: hsl(var(--white-color));
		border: 1px solid hsl(var(--gray-color));
		border-radius: var(--border-radius);
	}
	
	@media (hover: hover) {
		.select-primary:hover {
			border: 1px solid hsl(var(--dark-gray-color));
		}
	}
	
	.select-primary:focus {
		border: 1px solid hsl(var(--dark-gray-color));
		outline: none;
	}
	
	/*.dropdown-primary, .dropdown-special {
		position: relative;
	}
	
	.dropdown-primary button, .dropdown-special button {
		cursor: pointer;
		user-select: none;
	}
	
	.dropdown-primary button {
		width: 100%;
		padding: 8px 12px;
		background-color: hsl(var(--white-color));
		border: 1px solid hsl(var(--gray-color));
		border-radius: var(--border-radius);
		display: flex;
		justify-content: space-between;
		align-items: center;
		column-gap: var(--spacing-unit-s2);
	}
	
	.dropdown-special button {
		font-size: var(--font-size-s1);
		color: hsl(var(--black-color));
		background-color: hsl(var(--white-color));
		height: 24px;
		width: 24px;
		border: 1px solid hsl(var(--gray-color));
	}
	
	.dropdown-primary button:focus {
		border: 1px solid hsl(var(--dark-gray-color));
		outline: none;
	}
	
	.dropdown-special button:focus {
		outline: none;
	}
	
	.dropdown-primary :where(p, span) {
		pointer-events: none;
	}
	
	.dropdown-primary p {
		font-size: var(--font-size-s1);
		line-height: var(--line-height-s1);
		color: hsl(var(--black-color));
	}
	
	.dropdown-primary span {
		font-size: var(--font-size-default);
	}
	
	.dropdown-primary span::before {
		content: var(--down-arrow);
	}
	
	.dropdown-primary ul, .dropdown-special ul {
		list-style: none;
		padding-inline: unset;
		cursor: pointer;
	}
	
	.dropdown-primary :where(div, ul) {
		width: 100%;
	}
	
	.dropdown-primary :where(div, ul), .dropdown-special ul {
		background-color: hsl(var(--white-color));
		border-radius: var(--border-radius);
		overflow: hidden;
		margin-top: var(--spacing-unit-s4);
		box-shadow: var(--natural-shadow);
		display: none;
		position: absolute;
		z-index: 1;
	}
	
	.dropdown-primary .show {
		display: block;
	}
	
	.dropdown-primary :where(a, li), .dropdown-special li {
		font-size: var(--font-size-s1);
		line-height: var(--line-height-s1);
		color: hsl(var(--black-color));
		padding: 8px 12px;
		border-top: 1px solid transparent;
		border-bottom: 1px solid transparent;
		display: block;
	}
	
	.dropdown-primary :where(a, li):not(:last-child) {
		border-bottom: 1px solid hsl(var(--light-gray-color));
	}
	
	@media (hover: hover) {
		.dropdown-primary :where(a, li):hover:not(.selected) {
			background-color: hsl(var(--secondary-color));
		}
	}
	
	.dropdown-primary :where(a, li):focus {
		outline: none;
	}
	
	.dropdown-primary :where(a, li):focus:not(.selected) {
		background-color: hsl(var(--secondary-color));
	}
	
	.dropdown-primary .selected {
		font-weight: bold;
		color: hsl(var(--primary-color));
	}*/
	
	// #endregion
	
	// #region content style
	
	.content {
		display: flex;
		flex-direction: column;
		row-gap: var(--spacing-unit-default);
	}
	
	.content h1 {
		font-size: var(--font-size-l1);
		line-height: var(--line-height-l1);
		color: hsl(var(--black-color));
		text-align: center;
	}
	
	.content section {
		display: flex;
		flex-direction: column;
		row-gap: var(--spacing-unit-s1);
	}
	
	.content h2 {
		font-size: var(--font-size-default);
		line-height: var(--line-height-default);
		color: hsl(var(--black-color));
	}
	
	.content :where(p, blockquote, li) {
		font-size: var(--font-size-default);
		line-height: var(--line-height-default);
		color: hsl(var(--dark-gray-color));
	}
	
	.content a {
		text-decoration: underline;
		color: hsl(var(--primary-color));
	}
	
	.content small {
		font-size: var(--font-size-s1);
		line-height: var(--line-height-s1);
	}
	
	.content br:nth-of-type(2) {
		content: "";
		height: var(--spacing-unit-s1);
		width: fit-content;
		display: block;
	}
	
	// #endregion
	
	// #region input, checkbox style
	
	.input {
		font-size: var(--font-size-default);
		line-height: var(--line-height-default);
		color: hsl(var(--black-color));
		padding: 8px var(--spacing-unit-s1);
		border: 1px solid hsl(var(--gray-color));
		border-radius: var(--border-radius);
	}
	
	.input::placeholder {
		color: hsl(var(--gray-color));
	}
	
	.checkbox {
		height: var(--spacing-unit-s1);
		width: var(--spacing-unit-s1);
		border: 1px solid hsl(var(--gray-color));
		border-radius: 0;
	}
	
	@media (hover: hover) {
		.input:hover, .checkbox:hover {
			border: 1px solid hsl(var(--dark-gray-color));
		}
	}
	
	.input:focus, .checkbox:focus {
		border: 1px solid hsl(var(--dark-gray-color));
		outline: none;
	}
	
	.checkbox:checked {
		background-color: hsl(var(--primary-color));
		border: 1px solid hsl(var(--primary-color));
		position: relative;
	}
	
	.checkbox:checked::after {
		content: "";
		height: var(--spacing-unit-s2);
		width: 3px;
		border: solid hsl(var(--white-color));
		border-width: 0 2px 2px 0;
		transform: rotate(45deg);
		display: block;
		position: absolute;
		left: 4px;
	}
	
	// #endregion
	
	// #region Other style
	
	.line::before, .line-transparent::before {
		height: 1px;
		display: block;
		content: "";	
	}
	
	.line::before {
		background-color: hsl(var(--light-gray-color));
	}
	
	.line-transparent::before {
		background-color: transparent;
	}
	
	.border::before {
		height: var(--spacing-unit-s3);
		background-color: hsl(var(--secondary-color));
		border-top: 1px solid hsl(var(--light-gray-color));
		border-bottom: 1px solid hsl(var(--light-gray-color));
		display: block;
		content: "";
	}
	
	.option-color {
		display: flex;
		column-gap: var(--spacing-unit-s2);
		
		a {
			height: 64px;
			width: 64px;
			background-color: hsl(var(--light-gray-color));
			border: 1px solid hsl(var(--gray-color));
			border-radius: var(--border-radius);
		}
	}
	
	.option-size {
		display: flex;
		column-gap: var(--spacing-unit-s1);
		
		a {
			height: 37px;
			width: 37px;
			font-size: var(--font-size-s1);
			line-height: var(--line-height-s1);
			color: hsl(var(--black-color));
			border: 1px solid hsl(var(--gray-color));
			border-radius: var(--border-radius);
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	
	// #endregion
`;

export default GlobalStyle;