import styled from 'styled-components';

// #region Styled

const Carousel = styled.div`
	background-color: hsl(var(--secondary-color));
	border-bottom: 1px solid hsl(var(--light-gray-color));
	
	ul {
		list-style: none;
		padding-inline: unset;
	}
`;

const Image = styled.ul`
	background-color: hsl(var(--light-gray-color));
	aspect-ratio: 1.618/1;
`;

const Wrapper = styled.div`
	padding: 0 var(--spacing-unit-s1);
	margin-top: calc(var(--spacing-unit-l1) * -1);
	overflow: hidden;
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s2);
`;

const Dot = styled.ul`
	display: flex;
	justify-content: center;
	column-gap: var(--spacing-unit-s3);
	
	li {
		height: var(--spacing-unit-s3);
		width: var(--spacing-unit-s3);
		border-radius: 100%;
		background-color: hsla(var(--gray-color), 0.75);
	}
	
	.selected {
		background-color: hsl(var(--primary-color));
	}
`;

const Description = styled.div`
	padding: var(--spacing-unit-s1);
	background-color: hsl(var(--white-color));
	border-left: 1px solid hsl(var(--light-gray-color));
	border-top: 1px solid hsl(var(--light-gray-color));
	border-right: 1px solid hsl(var(--light-gray-color));
	border-radius: var(--border-radius) var(--border-radius) 0 0;
	box-shadow: var(--natural-shadow);
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s2);
`;

const Title = styled.div`
	font-size: var(--font-size-default);
	font-weight: bold;
	line-height: var(--line-height-default);
	color: hsl(var(--black-color));
`;

const Period = styled.div`
	font-size: var(--font-size-s1);
	line-height: var(--line-height-s1);
	color: hsl(var(--dark-gray-color));
`;

// #endregion

const PromotionCarousel = () => {
	return (
		<Carousel>
			<Image>
				<li></li>
				<li></li>
				<li></li>
			</Image>
			<Wrapper>
				<Dot>
					<li className='selected'></li>
					<li></li>
					<li></li>
				</Dot>
				<Description>
					<Title>หัวข้อโปรโมชั่นที่มีขนาดความยาวไม่เกิน 120 ตัวอักษร ขนาด 15px ตัวหนา สีดำ</Title>
					<Period>วันนี้ - 9 กันยายน 2566</Period>
				</Description>
			</Wrapper>
		</Carousel>
	);
}

export default PromotionCarousel;