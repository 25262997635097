import { Types } from '../config/types';
import { useContext } from 'react';
import { dataContext } from '../App';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import authorization from '../assets/graphics/authorization-2x.png';

// #region Styled

const Main = styled.main`
	padding: var(--spacing-unit-l1) var(--spacing-unit-s1);
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	row-gap: var(--spacing-unit-default);
	
	img {
		height: auto;
		width: 25%;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: var(--spacing-unit-s1);
`;

const Exclaim = styled.div`
	font-size: var(--font-size-l2);
	font-weight: bold;
	line-height: var(--line-height-l2);
	color: hsl(var(--black-color));
`;

const Content = styled.div`
	text-align: center;
	width: 75%;
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s2);
	
	h1 {
		font-size: var(--font-size-default);
		line-height: var(--line-height-default);
		color: hsl(var(--black-color));
	}
	
	p {
		font-size: var(--font-size-s1);
		line-height: var(--line-height-s1);
		color: hsl(var(--dark-gray-color));
	}
`;

// #endregion

const Authorization = () => {
	const { state } = useContext(dataContext) as Types;
	const {
		code: languageCode,
		name: languageName,
		text: languageText
	} = state.language as Types;
	
	return (
		<>
			<Main>
				<Wrapper>
					<img src={ authorization } alt={ `${ languageName } authorization` } />
					<Exclaim>Oops!</Exclaim>
					<Content>
						<h1>{ languageText.authorization.heading }</h1>
						<p>{ languageText.authorization.description }</p>
					</Content>
					<Link to={ `/${ languageCode }/account` } title='?' className='button-primary'>?</Link>
				</Wrapper>
			</Main>
		</>
	);
}

export default Authorization;