import { Types } from './config/types';
import { createContext, useReducer } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import useHandler from './hooks/useHandler';
import useLanguage from './hooks/useLanguage';
import useToken from './hooks/useToken';
import ScrollToTop from './hooks/ScrollToTop';
import NavigationTop from './components/NavigationTop';
import Footer from './components/Footer';
import NavigationBottom from './components/NavigationBottom';
import UnderConstruction from './pages/UnderConstruction';
import Home from './pages/Home';
import Products from './pages/Products';
import Cart from './pages/Cart';
import Order from './pages/Order';
import CreateAccount from './pages/CreateAccount';
import SignIn from './pages/SignIn';
import Account from './pages/Account';
import NewsAndEvents from './pages/NewsAndEvents';
import CookiesPolicy from './pages/CookiesPolicy';
import NotFound from './pages/NotFound';

const {
	APPLICATION_EVENT,
	APPLICATION_MODE
} = (window as any).env as Types;

const {
	REACT_APP_DEFAULT_LANGUAGE,
	REACT_APP_COOKIE_EXPIRE
} = (process as any).env as Types;

// #region language

const dataReducer = {
	language: useLanguage.get(useLanguage.lang(window.location.pathname)!, REACT_APP_DEFAULT_LANGUAGE, 'bkuc-config', REACT_APP_COOKIE_EXPIRE)
};

// #endregion

// #region console.log

if (APPLICATION_MODE === 'production') {
	const { warning: languageWarning } = dataReducer.language as Types;
	console.log(`%c${ languageWarning.heading }`, 'font-weight: bold; color: red;');
	console.log(languageWarning.description);
}

// #endregion

// #region check token

const { success, access_token } = useToken.check();

if (success) {
	Object.assign(dataReducer, { auth: useToken.payload(access_token!) });
} else {
	const object = {
		success: false
	}
	
	Object.assign(dataReducer, { auth: object });
}

// #endregion

// #region reducer

const reducer = (state: object, action: Types) => {
	switch(action.type) {
		case 'change_language':
			return { ...state, language: action.payload };
		case 'set_auth':
			return { ...state, auth: action.payload };
		default:
			useHandler.console(`Unknown action: ${ action.type }`);
			return { state };
	}
};

// #endregion

const dataContext = createContext({});

const App = () => {
	const [ state, dispatch ] = useReducer(reducer, dataReducer);
	const { code: languageCode } = state.language as Types;
	const { success: authSuccess } = state.auth as Types;
	
	return (
		<dataContext.Provider value={{ state, dispatch }}>
			<ScrollToTop />
			{(() => {
				switch(APPLICATION_EVENT) {
					case 'under-construction':
						return (
							<>
								<NavigationTop />
								<div className='fill-top' />
								<Routes>
									<Route path='*' element={ <UnderConstruction /> } />
								</Routes>
							</>
						);
					default:
						return (
							<>
								<NavigationTop />
								<div className='fill-top' />
								<Routes>
									<Route index element={ <Home /> } />
									<Route path={ `${ languageCode }/products` } element={ <Products /> }>
										<Route path=':id' element={ <Products /> } />
									</Route>
									<Route path={ `${ languageCode }/cart` } element={ <Cart /> } />
									<Route path={ `${ languageCode }/order` } element={ <Order /> } />
									<Route path={ `${ languageCode }/create-account` } element={ authSuccess ? <Navigate to='/' replace /> : <CreateAccount /> } />
									<Route path={ `${ languageCode }/sign-in` } element={ authSuccess ? <Navigate to='/' replace /> : <SignIn /> } />
									<Route path={ `${ languageCode }/account` } element={ <Account /> } />
									<Route path={ `${ languageCode }/news-and-events` } element={ <NewsAndEvents /> } />
									<Route path={ `${ languageCode }/cookies-policy` } element={ <CookiesPolicy /> } />
									<Route path='*' element={ <NotFound /> } />
								</Routes>
								<Footer />
								<NavigationBottom />
								<div className='fill-bottom'>
									<div className='safearea-bottom' />
								</div>
							</>
						);
				}
			})()}
		</dataContext.Provider>
	);
}

export { dataContext };
export default App;