import { Types } from '../config/types';
import { useContext, useRef } from 'react';
import { dataContext } from '../App';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import useHandler from '../hooks/useHandler';

// #region Styled

const Main = styled.main`
	padding: var(--spacing-unit-default) var(--spacing-unit-s1);
	flex: 1;
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-default);
`;

const Head = styled.div`
	font-size: var(--font-size-l1);
	font-weight: bold;
	line-height: var(--line-height-l1);
	color: hsl(var(--black-color));
	text-align: center;
`;

const Form = styled.form`
	padding: 0 var(--spacing-unit-default);
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s1);
`;

const Input = styled.div`
	position: relative;
	
	input {
		padding-left: 52px;
		width: 100%;
	}
	
	input[id=password] {
		padding-right: 52px;
	}
`;

const Label = styled.label<{ $icon?: string; }>`
	padding: ${ props => props.$icon === 'mail' ? '10px 15.5px 11px 15.5px' : '9px var(--spacing-unit-s1) 11px var(--spacing-unit-s1)' };
	position: absolute;
	
	&::before {
		content: var(--${ props => props.$icon });
		font-size: ${ props => props.$icon === 'mail' ? '21px' : '22px' };
		color: hsl(var(--dark-gray-color));
	}
`;

const Eye = styled.button`
	margin: 3.5px 7.5px;
	position: absolute;
	top: 0;
	right: 0;
	
	&::before {
		font-size: 22px;
		color: hsl(var(--dark-gray-color));
	}
	
	&.eye::before {
		content: var(--eye);
	}
	
	&.eye-closed::before {
		content: var(--eye-closed);
	}
`;

const Question = styled.div`
	font-size: var(--font-size-default);
	line-height: var(--line-height-default);
	color: hsl(var(--black-color));
	text-align: center;
	padding: 0 var(--spacing-unit-default);
`;

const Accept = styled.div`
	font-size: var(--font-size-s1);
	line-height: var(--line-height-s1);
	color: hsl(var(--dark-gray-color));
	text-align: center;
	padding: 0 var(--spacing-unit-default);
`;

// #endregion

const CreateAccount = () => {
	const {
		REACT_APP_USERNAME_MAXLENGTH,
		REACT_APP_EMAIL_MAXLENGTH,
		REACT_APP_PASSWORD_MAXLENGTH
	} = (process as any).env as Types;
	
	const { state } = useContext(dataContext) as Types;
	const {
		code: languageCode,
		head: languageHead,
		text: languageText
	} = state.language as Types;
	
	const formRef = useRef<HTMLFormElement>(null);
	const usernameRef = useRef<HTMLInputElement>(null);
	const emailRef = useRef<HTMLInputElement>(null);
	const passwordRef = useRef<HTMLInputElement>(null);
	
	// #region submitCreateAccount
	
	const submitCreateAccount = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		
		const username = usernameRef.current!.value;
		const email = emailRef.current!.value;
		const password = passwordRef.current!.value;
		
		if (username && email && password) {
			
			
			
			console.log(`Create account = Username : ${ username } / Email : ${ email } / Password : ${ password }`);
			
			
			
			formRef.current?.reset();
		}
	}
	
	// #endregion
	
	return (
		<>
			<Helmet>
				<title>{ languageHead.create_account.title }</title>
				<meta name='description' content={ languageHead.create_account.description } />
			</Helmet>
			<Main>
				<Head>{ languageText.create_account }</Head>
				<Form ref={ formRef } onSubmit={ submitCreateAccount }>
					<Input>
						<Label htmlFor='username' className='bophloi' $icon='user' />
						<input type='text' id='username' ref={ usernameRef } title={ languageText.username } maxLength={ REACT_APP_USERNAME_MAXLENGTH } placeholder={ languageText.username } autoComplete='off' className='input' />
					</Input>
					<Input>
						<Label htmlFor='email' className='bophloi' $icon='mail' />
						<input type='email' id='email' ref={ emailRef } title={ languageText.email } maxLength={ REACT_APP_EMAIL_MAXLENGTH } placeholder={ languageText.email } autoComplete='off' className='input' />
					</Input>
					<Input>
						<Label htmlFor='password' className='bophloi' $icon='lock' />
						<input type='password' id='password' ref={ passwordRef } title={ languageText.password } maxLength={ REACT_APP_PASSWORD_MAXLENGTH } placeholder={ languageText.password } autoComplete='new-password' className='input' />
						<Eye type='button' title={ languageText.show_password } className='button-icon bophloi eye-closed' onClick={ event => useHandler.eye(event, [languageText.hide_password, languageText.show_password], passwordRef) } />
					</Input>
					<input type='submit' value={ languageText.create_account } title={ languageText.create_account } className='button-primary' disabled />
				</Form>
				<Question>
					{ `${ languageText.already_have_an_account }? ` }
					<Link to={ `/${ languageCode }/sign-in` } title={ languageText.sign_in } className='link'>{ languageText.sign_in }</Link>
				</Question>
				<Accept>
					{ `เมื่อสมัครสมาชิก แสดงว่าคุณยอมรับ ` }
					<Link to={ `/${ languageCode }/terms-of-use` } title={ languageText.terms_of_use } className='link'>{ languageText.terms_of_use }</Link>
					{ ` และ ` }
					<Link to={ `/${ languageCode }/privacy-policy` } title={ languageText.privacy_policy } className='link'>{ languageText.privacy_policy }</Link>
					{ ` ของเราแล้ว` }
				</Accept>
			</Main>
			<div className='border' />
		</>
	);
}

export default CreateAccount;