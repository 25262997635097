import { Types } from '../config/types';
import useHandler from './useHandler';
import useCookie from './useCookie';

const { REACT_APP_COOKIE_EXPIRE } = (process as any).env as Types;

const useToken = {
	
	// #region set
	
	set: (type: string, atoken: string, rtoken: string) => {
		switch(type) {
			case 'session': sessionStorage.setItem('bkus-accesstoken', atoken); break;
			case 'local': localStorage.setItem('bkus-accesstoken', atoken);
		}
		
		useCookie.set('bkuc-refreshtoken', rtoken, REACT_APP_COOKIE_EXPIRE);
	},
	
	// #endregion
	
	// #region check
	
	check: () => {
		let success: boolean;
		let type: string | null = null;
		let accessToken: string | null = null;
		let refreshToken: string | null = null;
		
		// #region syn
		
		const syn = (atoken: string) => {
			const array = atoken.split('.');
			let result: string | null = null;
			
			try {
				const object: { syn: string } = JSON.parse(atob(array[1]));
				
				result = object.syn;
			} catch(err) {
				useHandler.console(err);
			}
			
			return result;
		}
		
		// #endregion
		
		const session = sessionStorage.getItem('bkus-accesstoken');
		const local = localStorage.getItem('bkus-accesstoken');
		const array = [
			session && { type: 'session', access: session },
			local && { type: 'local', access: local }
		];
		
		const filter = array.filter(element => element !== '' && element !== null);
		const object = filter[0] as { type: string, access: string };
		const refresh = useCookie.get('bkuc-refreshtoken');
		
		if (filter.length === 1 && refresh && (syn(object.access) === syn(refresh))) {
			success = true;
			type = object.type;
			accessToken = object.access;
			refreshToken = refresh;
		} else {
			success = false;
			
			useToken.remove();
		}
		
		const result = {
			success: success,
			type: type,
			access_token: accessToken,
			refresh_token: refreshToken
		};
		
		return result;
	},
	
	// #endregion
	
	// #region remove
	
	remove: () => {
		sessionStorage.removeItem('bkus-accesstoken');
		localStorage.removeItem('bkus-accesstoken');
		useCookie.delete('bkuc-refreshtoken');
	},
	
	// #endregion
	
	// #region payload
	
	payload: (atoken: string) => {
		const array = atoken.split('.');
		const object: { id: number, name: string, email: string, role: string } = JSON.parse(atob(array[1]));
		const result = {
			success: true,
			id: object.id,
			name: object.name,
			email: object.email,
			role: object.role
		};
		
		return result;
	}
	
	// #endregion
	
}

export default useToken;