import { Types } from '../config/types';
import { useContext } from 'react';
import { dataContext } from '../App';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import painting from '../assets/graphics/painting-2x.png';

// #region Styled

const Main = styled.main`
	padding: var(--spacing-unit-l1) var(--spacing-unit-s1);
	background-color: hsl(var(--secondary-color));
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	row-gap: var(--spacing-unit-s1);
	
	img {
		height: auto;
		width: 25%;
	}
`;

const Exclaim = styled.div`
	font-size: var(--font-size-l2);
	font-weight: bold;
	line-height: var(--line-height-l2);
	color: hsl(var(--black-color));
`;

const Content = styled.div`
	text-align: center;
	width: 75%;
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s2);
	
	h1 {
		font-size: var(--font-size-default);
		line-height: var(--line-height-default);
		color: hsl(var(--black-color));
	}
	
	p {
		font-size: var(--font-size-s1);
		line-height: var(--line-height-s1);
		color: hsl(var(--dark-gray-color));
	}
`;

const Footer = styled.footer`
	background-color: hsl(var(--secondary-color));
	
	P {
		font-size: var(--font-size-s1);
		line-height: var(--line-height-s1);
		color: hsl(var(--black-color));
		text-align: center;
		padding: var(--spacing-unit-default) var(--spacing-unit-s1);
	}
`;

const SafeArea = styled.div`
	height: calc(env(safe-area-inset-bottom) - var(--spacing-unit-default));
`;

// #endregion

const UnderConstruction = () => {
	const { state } = useContext(dataContext) as Types;
	const {
		name: languageName,
		head: languageHead,
		text: languageText,
		copyright: languageCopyright
	} = state.language as Types;
	
	return (
		<>
			<Helmet>
				<title>{ languageHead.under_construction.title }</title>
				<meta name='description' content={ languageHead.under_construction.description } />
			</Helmet>
			<Main>
				<img src={ painting } alt={ `${ languageName } Painting logo` } title={ `${ languageName } Painting logo` } />
				<Exclaim>Oops!</Exclaim>
				<Content>
					<h1>{ languageText.under_construction.heading }</h1>
					<p>{ languageText.under_construction.description }</p>
				</Content>
			</Main>
			<Footer>
				<p>{ languageCopyright }</p>
				<SafeArea></SafeArea>
			</Footer>
		</>
	);
}

export default UnderConstruction;