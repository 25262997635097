import { Types } from '../config/types';
import { useContext, useRef } from 'react';
import { dataContext } from '../App';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

// #region Styled

const Footers = styled.footer`
	padding: var(--spacing-unit-default) var(--spacing-unit-s1);
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-default);
`;

const Navigation = styled.nav`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: var(--spacing-unit-default) var(--spacing-unit-s1);
`;

const Wrapper = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s2);
`;

const Heads = styled.div`
	font-size: var(--font-size-default);
	font-weight: bold;
	line-height: var(--line-height-default);
	color: hsl(var(--black-color));
`;

const List = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: var(--spacing-unit-s3);
	
	div {
		font-size: var(--font-size-s1);
		line-height: var(--line-height-s1);
		color: hsl(var(--dark-gray-color));
	}
`;

const NavLinks = styled(NavLink)`
	font-size: var(--font-size-s1);
	line-height: var(--line-height-s1);
	color: hsl(var(--black-color));
	
	&.active {
		color: hsl(var(--primary-color));
		text-decoration: none;
	}
`;

const Subscribe = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s1);
	
	form {
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: var(--spacing-unit-s1);
	}
	
	input:first-child {
		text-align: center;
		width: 100%;
	}
`;

const Head = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s2);
	
	div:first-child {
		font-size: var(--font-size-default);
		font-weight: bold;
		line-height: var(--line-height-default);
		color: hsl(var(--black-color));
	}
	
	div:last-child {
		font-size: var(--font-size-s1);
		line-height: var(--line-height-s1);
		color: hsl(var(--dark-gray-color));
	}
`;

const Law = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-default);
	
	div {
		font-size: var(--font-size-s1);
		line-height: var(--line-height-s1);
		color: hsl(var(--black-color));
	}
`;

// #endregion

const Footer = () => {
	const { REACT_APP_EMAIL_MAXLENGTH } = (process as any).env as Types;
	
	const { state } = useContext(dataContext) as Types;
	const {
		code: languageCode,
		name: languageName,
		text: languageText,
		registered: languageRegistered,
		copyright: languageCopyright
	} = state.language as Types;
	
	const { success: authSuccess } = state.auth as Types;
	
	const formRef = useRef<HTMLFormElement>(null);
	const emailRef = useRef<HTMLInputElement>(null);

	// #region checkInput
	
	const checkInput = (event: React.FormEvent<HTMLFormElement>) => {
		const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		const email = emailRef.current!.value;
		const submit = event.currentTarget.querySelector('input[type=submit]');
		
		if (email && pattern.test(email)) {
			if (submit!.hasAttribute('disabled')) submit!.removeAttribute('disabled');
		} else {
			if (!submit!.hasAttribute('disabled')) submit!.setAttribute('disabled', '');
		}
	}
	
	// #endregion
	
	// #region submitSubscribe
	
	const submitSubscribe = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		
		const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		const email = emailRef.current!.value;
		const submit = event.currentTarget.querySelector('input[type=submit]');
		
		if (email && pattern.test(email)) {
			formRef.current?.reset();
			submit!.setAttribute('disabled', '');
			
			
			
			console.log(`Subscribe = Email : ${ emailRef.current!.value }`);
			
			
			
		}
	}
	
	// #endregion
	
	return (
		<Footers>
			<Navigation>
				<Wrapper>
					<Heads>{ languageName }</Heads>
					<List>
						<NavLinks to={ `/${ languageCode }/about-us` } title={ languageText.about_us } className='link-primary'>{ languageText.about_us }</NavLinks>
						<NavLinks to={ `/${ languageCode }/news-and-events` } title={ languageText.news_and_events } className='link-primary'>{ languageText.news_and_events }</NavLinks>
						<NavLinks to={ `/${ languageCode }/careers` } title={ languageText.careers } className='link-primary'>{ languageText.careers }</NavLinks>
					</List>
				</Wrapper>
				<Wrapper>
					<Heads>{ languageText.family }</Heads>
					<List>
						{ !authSuccess
							? <NavLinks to={ `/${ languageCode }/create-account` } title={ languageText.create_account } className='link-primary'>{ languageText.create_account }</NavLinks>
							: <div>{ languageText.create_account }</div>
						}
						<NavLinks to={ `/${ languageCode }/benefits` } title={ languageText.benefits } className='link-primary'>{ languageText.benefits }</NavLinks>
					</List>
				</Wrapper>
				<Wrapper>
					<Heads>{ languageText.help }</Heads>
					<List>
						<NavLinks to={ `/${ languageCode }/how-to-order` } title={ languageText.how_to_order } className='link-primary'>{ languageText.how_to_order }</NavLinks>
						<NavLinks to={ `/${ languageCode }/order-status` } title={ languageText.order_status } className='link-primary'>{ languageText.order_status }</NavLinks>
						<NavLinks to={ `/${ languageCode }/contact-us` } title={ languageText.contact_us } className='link-primary'>{ languageText.contact_us }</NavLinks>
					</List>
				</Wrapper>
				<Wrapper>
					<Heads>{ languageText.policy }</Heads>
					<List>
						<NavLinks to={ `/${ languageCode }/privacy-policy` } title={ languageText.privacy_policy } className='link-primary'>{ languageText.privacy_policy }</NavLinks>
						<NavLinks to={ `/${ languageCode }/cookies-policy` } title={ languageText.cookies_policy } className='link-primary'>{ languageText.cookies_policy }</NavLinks>
						<NavLinks to={ `/${ languageCode }/terms-of-use` } title={ languageText.terms_of_use } className='link-primary'>{ languageText.terms_of_use }</NavLinks>
					</List>
				</Wrapper>
			</Navigation>
			<Subscribe>
				<Head>
					<div>{ languageText.subscribe_enews_promotions.heading }</div>
					<div>{ languageText.subscribe_enews_promotions.description }</div>
				</Head>
				<form ref={ formRef } onInput={ checkInput } onSubmit={ submitSubscribe }>
					<input type='email' ref={ emailRef } title={ languageText.email } maxLength={ REACT_APP_EMAIL_MAXLENGTH } placeholder={ languageText.email } className='input' />
					<input type='submit' value={ languageText.subscribe } title={ languageText.subscribe } className='button-secondary' disabled />
				</form>
			</Subscribe>
			<Law>
				<div>{ languageRegistered }</div>
				<div>{ languageCopyright }</div>
			</Law>
		</Footers>
	);
}

export default Footer;