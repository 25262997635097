import { Types } from '../config/types';
import { useContext, useState, useRef, useEffect } from 'react';
import { dataContext } from '../App';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useHandler from '../hooks/useHandler';
import Skeleton from '../components/Skeleton';

// #region Styled

const Wrapper = styled.div`
	padding: 0 var(--spacing-unit-s1);
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s1);
`;

const Filter = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-default);
`;

const Option = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s2);
`;

const Group = styled.div`
	display: flex;
	column-gap: var(--spacing-unit-s1);
`;

const Form = styled.form`
	display: flex;
	align-items: center;
	column-gap: var(--spacing-unit-s2);
	flex: 1;
	
	label {
		font-size: var(--font-size-default);
		line-height: var(--line-height-default);
		color: hsl(var(--black-color));
	}
	
	select {
		flex: 1;
	}
`;

const Button = styled.button<{ $icon?: string; }>`
	&::before {
		content: var(--${ props => props.$icon });
	}
`;

const Status = styled.div`
	font-size: var(--font-size-s1);
	line-height: var(--line-height-s1);
	color: hsl(var(--dark-gray-color));
`;

const SeeMore = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const Head = styled.div`
	font-size: var(--font-size-l1);
	font-weight: bold;
	line-height: var(--line-height-l1);
	color: hsl(var(--black-color));
	text-align: center;
`;

const Links = styled(Link)`
	font-size: var(--font-size-default);
	line-height: var(--line-height-default);
	color: hsl(var(--dark-gray-color));
	padding-right: var(--spacing-unit-s1);
	overflow: hidden;
	position: relative;
	
	&::before {
		content: var(--right-arrow);
		position: absolute;
		top: 5px;
		right: -4px;
	}
`;

const List = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: var(--spacing-unit-s2);
`;

const Item = styled(Link)`
	border: 1px solid hsl(var(--light-gray-color));
	border-radius: var(--border-radius);
	overflow: hidden;
	
	@media (hover: hover) {
		&:hover {
			box-shadow: var(--natural-shadow);
		}
	}
	
	&:focus {
		outline: none;
		box-shadow: var(--natural-shadow);
	}
`;

const Tag = styled.div<{ $name?: string; }>`
	font-size: var(--font-size-s1);
	line-height: var(--line-height-s1);
	color: hsl(var(--white-color));
	padding: var(--spacing-box-unit);
	border-radius: var(--border-radius);
	position: absolute;
	top: var(--spacing-unit-s2);
	right: var(--spacing-unit-s2);
	
	${ props => {
		switch(props.$name) {
			case 'off-price':
				return 'background-color: hsl(var(--red-color));';
		}
	} }
`;

const Image = styled.div`
	background-color: hsl(var(--light-gray-color));
	aspect-ratio: 1;
	position: relative;
	
	img {
		height: auto;
		width: 100%;
		display: block;
	}
`;

const Description = styled.div`
	padding: var(--spacing-unit-s2);
	display: flex;
	flex-direction: column;
	row-gap: var(--spacing-unit-s4);
`;

const Title = styled.div`
	font-size: var(--font-size-s1);
	line-height: var(--line-height-s1);
	color: hsl(var(--black-color));
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	overflow: hidden;
`;

const Label = styled.span<{ $name?: string; }>`
	font-size: var(--font-size-s1);
	line-height: var(--line-height-s1);
	padding: var(--spacing-label-unit);
	margin-right: var(--spacing-unit-s3);
	
	${ props => {
		switch(props.$name) {
			case 'new-arrival':
				return `
					color: hsl(var(--white-color));
					background-color: hsl(var(--green-color));
				`;
			case 'best-seller':
				return `
					color: hsl(var(--white-color));
					background-color: hsl(var(--primary-color));
				`;
		}
	} }
`;

const Value = styled.div<{ $name?: string; }>`
	display: flex;
	align-items: center;
	
	div {
		${ props => {
			switch(props.$name) {
				case 'special-price':
					return 'color: hsl(var(--bright-red-color));';
				default:
					return 'color: hsl(var(--bright-primary-color));';
			}
		} }
	}
	
	span {
		font-size: var(--font-size-s1);
		font-weight: normal;
	}
	
	span:first-child {
		margin-left: var(--spacing-unit-s4);
	}
	
	span:nth-of-type(2) {
		color: hsl(var(--dark-gray-color));
		text-decoration: line-through;
		margin-left: var(--spacing-unit-s3);
	}
`;

const Symbol = styled.div`
	font-size: var(--font-size-s1);
	position: relative;
	top: -1px;
`;

const Price = styled.div`
	font-size: 17px;
	font-weight: 500;
	line-height: 26px;
	display: flex;
	align-items: center;
`;

const Stock = styled.div`
	font-size: var(--font-size-s1);
	line-height: var(--line-height-s1);
	color: hsl(var(--dark-gray-color));
	
	span {
		text-decoration: underline;
	}
`;

// #endregion

const ProductList = (props: { helmet?: boolean, title: string, option?: string, query?: string }) => {
	const { ENDPOINT } = (window as any).env as Types;
	const { state } = useContext(dataContext) as Types;
	const {
		code: languageCode,
		head: languageHead,
		text: languageText
	} = state.language as Types;
	
	const [ loading, setLoading ] = useState(true);
	const [ productList, setProductList ] = useState([]);
	const selectlRef = useRef<HTMLSelectElement>(null);
	
	const changeFilter = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		
		
		
		console.log(selectlRef.current!.value);


		
	}
	
	const createMarkup = (content: string, stock: number, unit: string) => {
		const object: { [index: string]: any } = {
			'{ stock }': stock,
			'{ unit }': unit
		}
		const regex = new RegExp(Object.keys(object).join('|'), 'g');
		const update = content.replace(regex, match => object[match]);
		
		return {__html: update};
	}
	
	useEffect(() => {
		fetch(`${ ENDPOINT }`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json'
			},
			body: JSON.stringify({
				query: `{ productList { item tag label title price unit special_price status stock } }`
			})
		})
		.then(response => response.json())
		.then(result => {
			setLoading(false);
			setProductList(result.data.productList);
		})
		.catch(error => useHandler.console(error));
		
		// eslint-disable-next-line
	}, []);
	
	return (
		<>
			{ props.helmet &&
				<Helmet>
					<title>{ languageHead.products.title }</title>
					<meta name='description' content={ languageHead.products.description } />
				</Helmet>
			}
			<Wrapper>
				{ loading
					? <Skeleton type='product_list' option={ props.option } limit={ 4 } />
					: <>
						{(() => {
							switch(props.option) {
								case 'filter':
									return (
										<Filter>
											<Head>{ props.title }</Head>
											<Option>
												<Group>
													<Form onChange={ changeFilter }>
														<label htmlFor='filter'>{ `${ languageText.sort_by } :` }</label>
														<select id='filter' ref={ selectlRef } className='select-primary'>
															<option value='recommended'>{ languageText.recommended_products }</option>
															<option value='new-arrival'>{ languageText.new_arrival.normal }</option>
															<option value='best-seller'>{ languageText.best_seller.normal }</option>
															<option value='low-high'>{ languageText.price_low_high }</option>
															<option value='high-low'>{ languageText.price_high_low }</option>
														</select>
													</Form>
													<Button title={ languageText.filters } className='button-special bophloi' $icon='filter-bar'>{ languageText.filters }</Button>
												</Group>
												<Status>แสดงสินค้า 1 - 4 จาก 20 รายการ</Status>
											</Option>
										</Filter>
									);
								default:
									return (
										<SeeMore>
											<Head>{ props.title }</Head>
											<Links to={ `/${ languageCode }/products${ props.query }` } title={ languageText.see_more } className='link-primary bophloi'>{ languageText.see_more }</Links>
										</SeeMore>
									);
							}
						})()}
						<List>
							{ productList.map(({ item, tag, label, title, price, unit, special_price, status, stock }: { item: number; tag: string; label: string; title: string; price: number; unit: string; special_price: number; status: string; stock: number; }) =>
								<Item key={ item } to={ `/${ languageCode }/products/${ title.replace(/ /g, '-').substring(0, 55) }-ite.${ item }` } title={ title.substring(0, 55) }>
									<Image>
										{(() => {
											switch(tag) {
												case 'off-price':
													return (
														<Tag $name='off-price'>{ price < 100 ? `-${ Math.floor((special_price/price)*100) }%` : `-฿${ price-special_price }` }</Tag>
													);
												default:
													return null;
											}
										})()}
										<img src={ `/images/products-item/${ item }.webp` } alt={ languageText.product_images } onError={ useHandler.image } />
									</Image>
									<Description>
										<Title>
											{(() => {
												switch(label) {
													case 'new-arrival':
														return ( <Label $name='new-arrival'>{ languageText.new_arrival.short }</Label> );
													case 'best-seller':
														return ( <Label $name='best-seller'>{ languageText.best_seller.short }</Label> );
													default:
														return null;
												}
											})()}
											{ title }
										</Title>
										<Value $name={ special_price ? 'special-price' : '' }>
											<Symbol>฿</Symbol>
											<Price>
												{ special_price ? special_price : price }
												<span>{ `/${ unit }` }</span>
												{ special_price ? <span>{ `${ languageText.list_price } ฿${ price }` }</span> : '' }
											</Price>
										</Value>
										{(() => {
											switch(status) {
												case 'out-of-stock':
													return ( <Stock>{ `${ languageText.out_of_stock }!` }</Stock> );
												case 'pre-order':
													return( <Stock>{ languageText.pre_order }</Stock> );
												default:
													return ( stock < 10 ? <Stock dangerouslySetInnerHTML={ createMarkup(`${ languageText.only_left_in_stock }!`, stock, unit) }></Stock> : <Stock>{ languageText.in_stock }</Stock> );
											}
										})()}
									</Description>
								</Item>
							)}
						</List>
					</>
				}
			</Wrapper>
		</>
	);
}

export default ProductList;